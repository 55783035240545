import { ChangeDetectionStrategy, Component, OnDestroy, OnInit, inject } from '@angular/core';
import { OrderFacade, PAYMENT_TYPES } from '@app/store/order';
import { CURRENCY_TYPES } from '@app/store/payment/enums';
import { Confirmable } from '@app/shared/decorators/confirmable.decorator';
import { TranslateService } from '@ngx-translate/core';
import { SidebarBaseComponent } from '@app/shell/sidebar/components/sidebar/sidebar.component';
import { ActivatedRoute, Router, createUrlTreeFromSnapshot } from '@angular/router';
import { FormService } from '../../services/form-new.service';
import { Grow } from '@app/shared/animations';
import { BehaviorSubject, Observable, combineLatest, delay, filter, map, take, tap } from 'rxjs';
import { FormValidators } from '@app/shared/forms';
import { AutosuggestClosestFacade, AutosuggestFacade } from '@app/store/heremaps';
import { HighlightService } from '../../../../services/highlight.service';
import { PopupService } from '@app/shared/popup';
import { PrerequisitiesWarningPopupComponent } from '../../../../shared/components/prerequisities-warning-popup/prerequisities-warning-popup.component';
import { SelectOptionsFacade } from '@app/store/select-options';
import { UserFacade } from '@app/modules/user/store/facades/user.facade';
import { Roles } from '@app/modules/permissions';

@Component({
    selector: 'app-clone-booking',
    templateUrl: './clone-booking.component.html',
    styleUrls: ['./clone-booking.component.scss', '../../../../../shell/sidebar/styles/sidebar.scss'],
    providers: [FormService],
    animations: [Grow],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CloneBookingComponent extends SidebarBaseComponent implements OnInit, OnDestroy {
    protected activatedRoute = inject(ActivatedRoute);
    protected router = inject(Router);
    protected formService = inject(FormService);
    protected readonly orderFacade = inject(OrderFacade);
    protected readonly translateService = inject(TranslateService);
    protected readonly autosuggestFacade = inject(AutosuggestFacade);
    protected readonly autosuggestClosestFacade = inject(AutosuggestClosestFacade);
    protected readonly highlightService = inject(HighlightService);
    protected readonly popupService = inject(PopupService);
    protected readonly selectOptionsFacade = inject(SelectOptionsFacade);
    private readonly userFacade = inject(UserFacade);
    private previousTransactionsMethods: string[] = [];

    orderId = this.activatedRoute.snapshot.firstChild?.paramMap.get('order') || null;
    bookingId = this.activatedRoute.snapshot.firstChild?.paramMap.get('booking-clone') || null;
    order$ = this.orderFacade.order$;
    isInvoiceMethod$ = new BehaviorSubject<boolean>(false);
    data?: { messages: string[]; confirmKey: string; cancelKey: string };
    partnersSelect$ = this.selectOptionsFacade.partners$;
    user$ = this.userFacade.user$;

    savedId$ = this.orderFacade.savedId$;
    saved$ = this.orderFacade.saved$;
    skipConfirm = false;

    paymentTypesItems$!: Observable<{ label: string; value: string }[]>;

    currencyTypes = Object.values(CURRENCY_TYPES).map((type) => ({
        label: type,
        value: type,
    }));

    form = this.formService.form;

    animationDisabled$ = new BehaviorSubject(true);

    autosuggestLoading$ = combineLatest([this.autosuggestFacade.autosuggestLoading$, this.autosuggestClosestFacade.autosuggestLoading$]).pipe(
        map((loaders) => loaders.includes(true)),
    );

    get touched() {
        return this.form.touched;
    }

    ngOnInit(): void {
        this.formService.addBooking();

        this.subscriptions$.add(
            this.savedId$
                .pipe(
                    filter((savedId): savedId is string => savedId !== null),
                    tap((savedId) => {
                        this.highlightService.setHighlight(savedId);
                        this.skipConfirm = true;
                        this.goBack();
                    }),
                )
                .subscribe(),
        );

        this.subscriptions$.add(
            this.formService.form.controls.transactions.valueChanges
                .pipe(
                    map((transactions) => {
                        this.logPaymentMethodChanges();
                        if (
                            transactions.some(
                                (transaction) =>
                                    transaction.paymentMethod === PAYMENT_TYPES.GROUP_SETTLEMENT || transaction.paymentMethod === PAYMENT_TYPES.COMPANY_INVOICE,
                            )
                        ) {
                            transactions.some((transaction) => {
                                transaction.paymentMethod === PAYMENT_TYPES.COMPANY_INVOICE
                                    ? this.isInvoiceMethod$.next(true)
                                    : this.isInvoiceMethod$.next(false);
                                this.form.controls.invoiceDetails.patchValue({
                                    isCompany: true,
                                    invoice: true,
                                });

                                if (transaction.paymentMethod === PAYMENT_TYPES.GROUP_SETTLEMENT) {
                                    this.form.controls.invoiceDetails.controls.isCompany.enable();
                                    this.form.controls.invoiceDetails.controls.invoice.enable();
                                } else {
                                    this.form.controls.invoiceDetails.controls.isCompany.disable();
                                    this.form.controls.invoiceDetails.controls.invoice.disable();
                                }
                            });
                            this.form.controls.details.patchValue({
                                partner: this.form.controls.details.controls.partner.getRawValue(),
                            });

                            this.form.controls.invoiceDetails.controls.isCompany.setValidators(FormValidators.RequiredTrue);
                            this.form.controls.invoiceDetails.controls.invoice.setValidators(FormValidators.RequiredTrue);
                            this.form.controls.invoiceDetails.controls.nip.addValidators([FormValidators.Required]);
                        } else {
                            this.isInvoiceMethod$.next(false);
                            this.form.controls.invoiceDetails.controls.isCompany.clearValidators();
                            this.form.controls.invoiceDetails.controls.invoice.clearValidators();
                            this.form.controls.invoiceDetails.controls.nip.removeValidators([FormValidators.Required]);
                            this.form.controls.invoiceDetails.controls.isCompany.enable();
                            this.form.controls.invoiceDetails.controls.invoice.enable();
                        }
                        this.form.updateValueAndValidity();
                    }),
                )
                .subscribe(),
        );

        this.subscriptions$.add(
            this.formService.form.controls.invoiceDetails.controls.invoice.valueChanges
                .pipe(
                    tap((invoice) => {
                        if (invoice === false) {
                            this.formService.form.controls.invoiceDetails.patchValue({ isCompany: false });
                            this.form.controls.invoiceDetails.controls.nip.disable();
                            this.form.controls.invoiceDetails.controls.companyName.disable();
                        } else {
                            this.form.controls.invoiceDetails.controls.nip.enable();
                            this.form.controls.invoiceDetails.controls.companyName.enable();
                        }
                    }),
                )
                .subscribe(),
        );

        this.subscriptions$.add(
            this.order$
                .pipe(
                    take(1),
                    tap((order) => this.formService.setValue(order, <string>this.bookingId)),
                    delay(1),
                    tap(() => this.animationDisabled$.next(false)),
                    take(1),
                )
                .subscribe(),
        );

        if (this.orderId === null || this.bookingId === null) {
            this.close();
        } else {
            this.orderFacade.getOrder(this.orderId);
        }
        this.subscriptions$.add(
            this.isInvoiceMethod$
                .pipe(
                    tap((isInvoice) => {
                        if (isInvoice) {
                            this.form.controls.invoiceDetails.patchValue({
                                isCompany: isInvoice,
                                invoice: true,
                                nip: this.form.controls.invoiceDetails.controls.nip.value,
                                companyName: '',
                                address: {
                                    city: '',
                                    country: '',
                                    houseNumber: '',
                                    postalCode: '',
                                    street: '',
                                },
                            });
                        }
                    }),
                )
                .subscribe(),
        );
        this.subscriptions$.add(
            this.popupService.closingSubject
                .pipe(
                    filter((reason) => reason === 'confirmed'),
                    tap(() => this.addOrder()),
                )
                .subscribe(),
        );

        this.subscriptions$.add(
            this.orderFacade.checkPrerequisities$
                .pipe(
                    tap((prerequisities) => {
                        if (prerequisities.hasDuplicate || prerequisities.isPast) {
                            this.data = {
                                messages: [],
                                cancelKey: 'button.no',
                                confirmKey: 'button.yes',
                            };
                            if (prerequisities.hasDuplicate) {
                                this.data.messages.push(this.translateService.instant('MODAL.duplicate_warning'));
                            }
                            if (prerequisities.isPast) {
                                this.data.messages.push(this.translateService.instant('MODAL.is_past_warning'));
                            }
                            this.popupService.open(PrerequisitiesWarningPopupComponent, this.data);
                        } else {
                            this.addOrder();
                        }
                    }),
                )
                .subscribe(),
        );
        this.paymentTypesItems$ = combineLatest([this.user$]).pipe(
            map(([user]) => {
                return Object.values(PAYMENT_TYPES)
                    .filter(
                        (type) =>
                            !(
                                (user?.role === Roles.ROLE_PARTNER_EMPLOYEE || user?.role === Roles.ROLE_PARTNER_ACCOUNTANT) &&
                                (type === PAYMENT_TYPES.GROUP_SETTLEMENT || type === PAYMENT_TYPES.CASH || type === PAYMENT_TYPES.FREE_CHECKOUT)
                            ),
                    )
                    .map((type) => ({
                        label: <string>this.translateService.instant(`payment.payment-type.${type}.label`),
                        value: type,
                    }));
            }),
            tap((paymentTypes) => {
                const currentPaymentType = this.form.controls.transactions.at(0).controls.paymentMethod.value;
                if (!paymentTypes.find((paymentType) => paymentType.value === currentPaymentType)) {
                    this.form.controls.transactions.at(0).controls.paymentMethod.setValue(null);
                }
            }),
        );
        this.subscriptions$.add(
            this.order$
                .pipe(
                    take(1),
                    tap(() => {
                        this.form.getRawValue().transactions.forEach((transaction) => {
                            this.previousTransactionsMethods.push(transaction.paymentMethod!);
                        });
                    }),
                )
                .subscribe(),
        );
    }
    private logPaymentMethodChanges(): void {
        this.form.getRawValue().transactions.forEach((transaction, index) => {
            const previousTransaction = this.previousTransactionsMethods[index];

            if (previousTransaction) {
                const previousPaymentMethod = previousTransaction;
                const currentPaymentMethod = transaction.paymentMethod;

                if (
                    (previousPaymentMethod === PAYMENT_TYPES.GROUP_SETTLEMENT || previousPaymentMethod === PAYMENT_TYPES.COMPANY_INVOICE) &&
                    currentPaymentMethod !== previousPaymentMethod &&
                    currentPaymentMethod !== PAYMENT_TYPES.COMPANY_INVOICE &&
                    currentPaymentMethod !== PAYMENT_TYPES.GROUP_SETTLEMENT
                ) {
                    this.form.controls.invoiceDetails.patchValue({
                        isCompany: false,
                        invoice: false,
                    });
                }
            }
        });

        const paymentMethods = this.form
            .getRawValue()
            .transactions.flatMap((transaction) => transaction.paymentMethod || [])
            .filter((method) => method !== null && method !== undefined);

        this.previousTransactionsMethods = paymentMethods;
    }

    passengerName(i: number) {
        return `${this.form.controls.passengers.at(i).value.personalDetails?.firstname || ''} ${
            this.form.controls.passengers.at(i).value.personalDetails?.surname || ''
        }`.trim();
    }

    onAddBookingClick(event: MouseEvent) {
        event.preventDefault();
        this.formService.addBooking();
    }

    cancel(event: MouseEvent) {
        event.preventDefault();
        this.close();
    }

    onRemoveBookingClick(event: MouseEvent | KeyboardEvent, index: number) {
        event.preventDefault();
        this.formService.removeBooking(index);
    }

    get customerPersonaDetails() {
        return {
            firstname: this.form.controls.details.controls.firstName.value,
            surname: this.form.controls.details.controls.lastName.value,
            email: this.form.controls.details.controls.email.value,
            phone: this.form.controls.details.controls.phone.value,
        };
    }

    addOrder() {
        this.form.markAllAsTouched();
        if (this.form.valid) {
            const formRaw = this.form.getRawValue();
            const returns = formRaw.passengers
                .filter((passenger) => passenger.returnRouteActive === true)
                .map((passenger) => ({
                    ...passenger,
                    ...passenger.returnRoute,
                }));
            formRaw.passengers.push(...returns);
            this.orderFacade.addOrder(formRaw);
        }
    }

    onSubmit() {
        this.form.markAllAsTouched();
        if (this.form.valid) {
            const formRaw = this.form.getRawValue();
            const returns = formRaw.passengers
                .filter((passenger) => passenger.returnRouteActive === true)
                .map((passenger) => ({
                    ...passenger,
                    ...passenger.returnRoute,
                }));
            formRaw.passengers.push(...returns);
            this.orderFacade.checkPrerequisitiesCreate(formRaw);
        }
    }

    @Confirmable({ messageKey: 'MODAL.exit_without_save', confirmKey: 'MODAL.yes', cancelKey: 'MODAL.no' })
    close() {
        this.skipConfirm = true;
        this.goBack();
    }

    override ngOnDestroy(): void {
        this.orderFacade.clear();
        super.ngOnDestroy();
    }

    protected goBack() {
        const tree = createUrlTreeFromSnapshot(this.activatedRoute.snapshot, []);
        const params = { ...tree.root.children['primary'].segments[0].parameters };
        delete params['booking-clone'];
        delete params['order'];
        tree.root.children['primary'].segments[0].parameters = params;
        this.router.navigateByUrl(tree.toString(), { onSameUrlNavigation: 'reload' });
    }
}
